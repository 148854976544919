import { useEffect, useRef, useState } from 'react';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss'
import emailjs from '@emailjs/browser';

const Contact = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const [isSending, setSending] = useState(false)
    const refForm = useRef()

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000);
    })

    // const MailJSToName =
    // {
    //     to_name: "Xiangwei",
    // }

    const sendEmail = (e) =>{
        e.preventDefault();
        setSending(true)
        emailjs.sendForm('service_joalyyb', 'template_0zxo1oy', refForm.current, 'vSrSeUCnJCQ7c1iAd')
        .then((result) => {
            console.log(result.text);
            refForm.current.reset()
            alert('successfully sent!')
            setSending(false)
        }, (error) => {
            console.log(error.text);
            alert('Something went wrong, please try again.')
            setSending(false)
        });
    }

    return (
        <>
            <div className="container contact-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={'Contact me'.split('')}
                            idx={15}
                        />
                    </h1>

                    <div className="contact-form">
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input
                                        type='text'
                                        placeholder='Name'
                                        name='from_name'
                                        required
                                    />
                                </li>
                                <li className='half'>
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        name='reply_to'
                                        required
                                    />
                                </li>
                                <li>
                                   <input
                                        type='text'
                                        placeholder='Subject'
                                        name='subject_body'
                                        required
                                    />
                                </li>
                                <li>
                                    <textarea
                                        placeholder='Message'
                                        name='message'
                                        required
                                    />
                                </li>

                                <li>
                                    <input
                                    type='text'
                                    name='to_name'
                                    value='Xiangwei'
                                    style={{display: 'none'}}
                                    />
                                </li>

                                <li>
                                    <button 
                                        type='submit'
                                        className='flat-button'
                                    >
                                        {isSending ? 'Sending' : 'Send'}    
                                    </button>
                                </li>

                            </ul>
                        </form>
                    </div>
                </div>
            </div>

            <Loader type='line-scale-pulse-out-rapid' />
        </>
    )
}

export default Contact;